import moment from 'moment'

export class GlobalControl {
    globals = true;
    switchLabel = '';
    initDate = ''
    endDate = ''

    isFunnelEmpty = false
    isImpactCostEmpty = false

    constructor (initDate = null, endDate = null) {
        this.initDate = initDate === null ? moment().subtract(3, 'months').format('YYYY-MM-DD') : initDate
        this.endDate = endDate === null ? moment().format('YYYY-MM-DD') : endDate
    }

    setCurrentMontDates () {
        this.initDate = moment().startOf('month').format('YYYY-MM-DD')
        this.endDate = moment().endOf('month').format('YYYY-MM-DD')
    }

    getStorageKey (prefix) {
        return prefix + '-' + this.initDate + '-' + this.endDate + '-' + (this.globals ? 'global' : 'user')
    }

    getImpactCostStorageKey (daysOrHour) {
        return this.getStorageKey('impact-cost-' + (daysOrHour ? 'days' : 'hour'))
    }

    getFunnelStorageKey () {
        return this.getStorageKey('funnel')
    }

    setDates () {
        this.initDate = ''
        this.endDate = ''
    }
}
