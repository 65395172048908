import { Service } from './service'

const PATH = 'billing'

class BillingService extends Service {
  getBillingInvoiceDetail (invoiceId, params = {}) {
    return this.get(PATH + '/invoice' + '/' + invoiceId + '/iframe', params)
  }

  getAll (params) {
    return this.get(PATH, params)
  }
}

export default new BillingService()
